
export const Menu = () => {

  const drawerToggle = () => {
    let drawer = document.querySelector(`.drawer-toggle`)

    drawer.addEventListener( "click" , () => {
      let elmClassList = document.body.classList

      if (elmClassList.contains("drawer-close")) {
        elmClassList.remove("drawer-close")
        elmClassList.add("drawer-open")
        document.querySelector(".wrapper").style.position = "fixed"
      }else if (elmClassList.contains("drawer-open")) {
        elmClassList.remove("drawer-open")
        elmClassList.add("drawer-close")
        document.querySelector(".wrapper").style.position = "initial"
      } 
    })
  }
  window.addEventListener("DOMContentLoaded", drawerToggle)
}
