//import js
import "@babel/polyfill";
import "core-js/stable";
import "regenerator-runtime/runtime.js";
import "scroll-behavior-polyfill";

import { Drawer, Menu } from "./common/Drawer";
import { Main, jQueryFunctions } from "./common/Main";
import { Exam } from "./pages/Exam";
import { Form } from "./pages/Form";
import { Page } from "./pages/Page";
import { Question } from "./pages/Question";
import { TestList } from "./pages/TestList";
import { TestRequest } from "./pages/TestRequest";
import { Company } from "./pages/Company";
import { scrollToTop, scrollToId } from "./common/Polyfill";
import Scroll from "./common/Scroll";
import ScrollTop from "./common/ScrollTop";

Scroll();
ScrollTop();
scrollToId();
scrollToTop();

//do func for each pages
//Drawer();
Menu();
jQueryFunctions();
Main();

Company();
Exam();
Form();
Page();
Question();
TestList();
TestRequest();
