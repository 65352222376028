const Scroll = () => {
  const links = document.querySelectorAll('a[href^="#contents"]');

  for (let i = 0; i < links.length; i++) {
    links[i].addEventListener("click", (e) => {
      e.preventDefault();

      const elemY = links[i].getBoundingClientRect().top;

      const scrollY = window.pageYOffset;

      const top = elemY - scrollY;

      window.scroll({
        top: top,
        behavior: "smooth",
      });
    });
  }
};

export default Scroll;
