import "../../css/scss/common/common.scss";
import $ from "jquery";
import ScrollHint from "scroll-hint";
import "scroll-behavior-polyfill";

export const Main = () => {
  document.addEventListener(
    "wpcf7mailsent",
    function (event) {
      location.href = `${location.origin}/form/finish#finish`;
    },
    false
  );
  document.addEventListener(
    "wpcf7invalid",
    () => {
      location.href = `${location.origin}/form/#formTop`;
    },
    false
  );

  new ScrollHint(".table01", {
    scrollHintIconAppendClass: "scroll-hint-icon-white", // white-icon will appear
    applyToParents: true,
    i18n: {
      scrollable: "スクロールできます",
    },
  });

  // 一段目のニュースがアコーディオンなら開く、遷移ボタンならアクションなし
  typeof window !== "undefined" &&
    window.addEventListener("load", () => {
      typeof document.getElementsByClassName("NewsList__item-link")[0] !==
        "undefined" && topNewsOpener();
    });

  function topNewsOpener() {
    const column = document.getElementsByClassName("NewsList__item-link")[0];
    const window1 = document.getElementsByClassName(
      "NewsList__item--contents"
    )[0];
    const arrow = document.getElementsByClassName("NewsList__item-link")[0];
    if (
      column.classList.contains("js-accordion") == true &&
      column.classList.length > 0
    ) {
      window1.style.display = "block";
      arrow.classList.add("out");
    }
  }

  // hover font bold
  function hoverFontBold(hoverPart, changePart1, changePart2) {
    const target = document.getElementById(hoverPart);
    const changeFont1 = document.getElementById(changePart1);
    const changeFont2 = document.getElementById(changePart2);
    $(target).hover(
      function () {
        $(changeFont1).css("font-weight", "bold");
        $(changeFont2).css("font-weight", "bold");
      },
      function () {
        $(changeFont1).css("font-weight", "100");
        $(changeFont2).css("font-weight", "100");
      }
    );
  }

  hoverFontBold("js-hover-font-bold1", "js-change-font1", "js-change-font2");
  hoverFontBold("js-hover-font-bold2", "js-change-font3", "js-change-font4");
  hoverFontBold("js-hover-font-bold3", "js-change-font5", "js-change-font6");
  hoverFontBold("js-hover-font-bold4", "js-change-font7", "js-change-font8");
  hoverFontBold("js-hover-font-bold5", "js-change-font9", "js-change-font10");
  hoverFontBold("js-hover-font-bold6", "js-change-font11", "js-change-font12");
};

export const jQueryFunctions = () => {
  typeof window !== `undefined` &&
    window.addEventListener(
      "load",
      $(function () {
        $Functions();
      })
    );
};

const $Functions = () => {
  //要素////////////////////////////////////////////////////////////////////////////////
  var loader = $("#loader");
  var wd = $(window);
  var body = $("body");
  var html = $("html, body");
  var href = $('a[href^="#"]');
  var snsIcon = $(".snsIcon-js");
  var ua = navigator.userAgent;
  var $breakPoint = 769;
  ///SP用パララックス//////////////////////////////////////////////////////////////////////////////////
  wd.on("load", function () {
    var parallaxBg = (function () {
      var $winTop = wd.scrollTop();
      var $target = $(".parallax-bg");
      var $pageHeight = $(document).innerHeight();
      var $bottom = $pageHeight + wd.height();
      var $winWidth = wd.width();
      var $winHeight = wd.height();
      var $Adjustment = 300;
      var objArr = ["sp_photo1", "sp_photo2", "sp_photo3", "sp_photo4"];
      var plPosArr = [];
      $target.each(function (index) {
        var $imgPos = $(this).offset().top;
        var $position = $imgPos;
        if ($position >= 0) {
          plPosArr[index] = $position - $pageHeight * 0.12;
        } else {
          plPosArr[index] = $position + $pageHeight + $Adjustment;
        }
      });

      if (plPosArr.length == 1) return;

      parallaxBgInit(objArr, $target, objArr[0]);
      if ($winWidth < $breakPoint) {
        //3つまで出力　それ以上に増やしたい場合は以下の修正が必要
        wd.scroll(function () {
          $winTop = wd.scrollTop();

          if ($winTop > plPosArr[3]) {
            parallaxBgInit(objArr, $target, objArr[3]);
          } else if ($winTop > plPosArr[2]) {
            parallaxBgInit(objArr, $target, objArr[2]);
          } else if ($winTop > plPosArr[1]) {
            parallaxBgInit(objArr, $target, objArr[1]);
          } else if ($winTop > plPosArr[0]) {
            parallaxBgInit(objArr, $target, objArr[0]);
          } else {
            parallaxBgInit(objArr, $target, objArr[0]);
          }
        });
      }
    })();
  });
  //初期化
  function parallaxBgInit(arr, target, className) {
    className && target.addClass(className);
    className && target.addClass("fade");
    $.each(arr, function (index, value) {
      if (value != className) {
        target.removeClass("fade");
        target.removeClass(value);
      }
    });
  }
  ///エフェクト//////////////////////////////////////////////////////////////////////////////////
  $(".js-effect").css("opacity", "0");
  wd.scroll(function () {
    effectInit();
  });
  function effectInit() {
    $(".js-effect").each(function () {
      var imgPos = $(this).offset().top;
      var scroll = wd.scrollTop();
      var windowHeight = wd.height();

      if (scroll > imgPos - windowHeight + windowHeight / 5)
        $(this).css("opacity", "1");
      else $(this).css("opacity", "0");
    });
  }
  //go to top///////////////////////////////////////////////////////////////////////////
  // var topBtn = $(".backToTop");
  // wd.scroll(function () {
  //   if ($(this).scrollTop() > 100) {
  //     topBtn.css({ opacity: 0.9 });
  //     topBtn.css({ display: "block" });
  //   } else {
  //     topBtn.css({ opacity: 0 });
  //     topBtn.css({ display: "none" });
  //   }
  // });
  // var topBtnSp = $(".backToTopSp");
  // wd.scroll(function () {
  //   if ($(this).scrollTop() > 100) {
  //     topBtnSp.css({ opacity: 1 });
  //   } else {
  //     topBtnSp.css({ opacity: 0 });
  //   }
  // });
  // サイドナビゲーション///////////////////////////////////////////////////////////////////////
  var sideNav = $(".sideNav");
  var windowHeight = $(window).height(); //ウインドウの高さを取得
  var distance = 0;
  var scrollEnd = $(".Footer").offset().top;
  wd.scroll(function () {
    distance = $(this).scrollTop(); //スクロールした距離を取得
    if ($(this).scrollTop() > 500) {
      sideNav.css({ opacity: 1 });
    } else {
      sideNav.css({ opacity: 0 });
    }
  });
  // sideナビゲーションバー/////////////////////////////////////////////////////////////////////
  sideNav.on("click", function () {
    if (sideNav.hasClass("off")) {
      sideNav.removeClass("off");
      $(this).animate({ marginRight: "0px" }, 300);
    } else {
      sideNav.addClass("off");
      $(this).animate({ marginRight: "150px" }, 300);
    }
  });
  $(".dot-overlay").on("click", function () {
    if ($(".sideNav").hasClass("off")) {
      // ナビが表示されていたらcloseを実行
      $(".sideNav").trigger("click");
    } else {
      // ナビが非表示の場合は起動しない
      event.stopPropagation();
    }
  });
  $(".MainV__doc").on("click", function () {
    if ($(".sideNav").hasClass("off")) {
      // ナビが表示されていたらcloseを実行
      $(".sideNav").trigger("click");
    } else {
      // ナビが非表示の場合は起動しない
      event.stopPropagation();
    }
  });
  $(".contentWrap").on("click", function () {
    if ($(".sideNav").hasClass("off")) {
      // ナビが表示されていたらcloseを実行
      $(".sideNav").trigger("click");
    }
  });
  $(".Footer").on("click", function () {
    if ($(".sideNav").hasClass("off")) {
      // ナビが表示されていたらcloseを実行
      $(".sideNav").trigger("click");
    } else {
      // ナビが非表示の場合は起動しない
      event.stopPropagation();
    }
  });

  // spナビゲーションバー///////////////////////////////////////////////////////////////////////
  var startPos = 0,
    winScrollTop = 0;
  $(".spSideNav").addClass("hide");
  $(window).on("scroll", function () {
    winScrollTop = $(this).scrollTop();
    if (winScrollTop >= startPos) {
      $(".spSideNav").removeClass("hide");
    } else {
      $(".spSideNav").addClass("hide");
    }
    startPos = winScrollTop;
  });
  // アコーディオンアンカーリンク//////////////////////////////////////////////////////////////////////
  $(window).on("load hashchange", function () {
    var hash = location.hash;
    var t = 0;
    var headerHeight = $(".Header__inner").outerHeight();
    if (hash != "") {
      if (hash === "#accordionLink1") {
        $("#openFind").addClass("up");
        if ($("#openFind").hasClass("up")) {
          $("#openFind").next().show();
          $("html,body").animate({
            scrollTop: $("#accordionLink1").offset().top - 30,
          });
        }
      } else if (hash === "#accordionLink2") {
        $("#openFind2").addClass("up");
        if ($("#openFind2").hasClass("up")) {
          $("#openFind2").next().show();
          $("html,body").animate({
            scrollTop: $("#accordionLink2").offset().top,
          });
        }
      } else if (hash === "#accordionLink3") {
        if ($("#openFind3").hasClass("up")) {
          $("#openFind3").next().show();
          $("html,body").animate({
            scrollTop: $("#accordionLink3").offset().top,
          });
        }
      } else if (hash === "#accordionLink4") {
        $("#openFind4").addClass("arrowDown");
        if ($("#openFind4").hasClass("arrowDown")) {
          $("#openFind4").next().show();
          $("html,body").animate({
            scrollTop: $("#accordionLink4").offset().top - 90,
          });
        }
      }
      // トップページ各試験問い合わせ///////////////////////////////////
      if (hash === "#occID=eye") {
        $('select[name="menu-552"]').val("眼の試験");
        $("html,body").animate({ scrollTop: $("#formTop").offset().top - 90 });
      } else if (hash === "#occID=skin") {
        $('select[name="menu-552').val("皮膚の試験");
        $("html,body").animate({ scrollTop: $("#formTop").offset().top - 90 });
      } else if (hash === "#occID=safety") {
        $('select[name="menu-552"]').val("安全性試験");
        $("html,body").animate({ scrollTop: $("#formTop").offset().top - 90 });
      } else if (hash === "#occID=human_skin") {
        $('select[name="menu-552"]').val("ヒト皮膚の試験");
        $("html,body").animate({ scrollTop: $("#formTop").offset().top - 90 });
      } else if (hash === "#occID=other") {
        $('select[name="menu-552"]').val("その他の受託試験");
        $("html,body").animate({ scrollTop: $("#formTop").offset().top - 90 });
      } else if (hash === "#occID=sds") {
        $('select[name="menu-552"]').val("SDS・社内資料作成のための試験");
        $("html,body").animate({ scrollTop: $("#formTop").offset().top - 90 });
      } else if (hash === "#occID=various") {
        $('select[name="menu-552"]').val("各種申請のための試験");
        $("html,body").animate({ scrollTop: $("#formTop").offset().top - 90 });
      } else if (hash === "#occID=recruit") {
        $('select[name="menu-552"]').val("求人");
        $("html,body").animate({ scrollTop: $("#formTop").offset().top - 90 });
      }
    }
    history.replaceState('', document.title, window.location.pathname);
  });
  //スムーススクロール///////////////////////////////////////////////////////////////////////////
  //safariのみ
  let userAgent = window.navigator.userAgent.toLowerCase();
  userAgent.indexOf("safari") !== -1 &&
    userAgent.indexOf("chrome") === -1 &&
    (() => {
      href.on("click", function () {
        var speed = 400;
        var href = $(this).attr("href");
        var target = $(href == "#" || href == "" ? "html" : href);
        var position = target.offset().top;
        $("body,html").animate(
          {
            scrollTop: position,
          },
          speed,
          "linear"
        );
        return false;
      });
    });

  //ヘッダー固定////////////////////////////////////////////////////////////////////////////////
  let headerFixed = {
    classFixed: "fixed",
    headerObj: ".Header",
    wrapperObj: "body",
    init: function () {
      wd.scroll(
        function () {
          //if(!$(".drawer-open")[0]){
          var headerHeight = $(this.headerObj).height();
          var scrollTop = $(document).scrollTop();
          if (scrollTop > 300) headerFixed.addClass();
          else {
            scrollTop > headerHeight && headerFixed.removeClass();
          }

          //}
        }.bind(this)
      );
    },
    addClass: function () {
      this.AddHeaderHeight();
      $(this.headerObj).addClass(this.classFixed);
      $("#testMenu__wrap").addClass("addBack");
    },
    removeClass: function () {
      this.RemoveHeaderHeight();
      $(this.headerObj).removeClass(this.classFixed);
      $("#testMenu__wrap").removeClass("addBack");
    },
    AddHeaderHeight: function () {
      // $(this.wrapperObj).css({"padding-top":$(this.headerObj).height()});
    },
    RemoveHeaderHeight: function () {
      $(this.wrapperObj).css({ "padding-top": 0 });
    },
  };
  headerFixed.init();

  //IE11 対応/////////////////////////////////////////////////////////////////////////
  if (
    ua.match(/MSIE 10/i) ||
    ua.match(/Trident\/7\./) ||
    ua.match(/Edge\/12\./)
  ) {
    body.on("mousewheel", function () {
      event.preventDefault();
      var weelD = event.wheelDelta;
      var poff = window.pageYOffset;
      window.scrollTo(0, poff - weelD);
    });
  }
  //アコーディオン/////////////////////////////////////////////////////////////////////////
  var accodion = function () {
    $(".js-accordion").on({
      click: function () {
        $(this).next().slideToggle("slow");
        $(this).hasClass("plus") && $(this).toggleClass("minus");
        $(this).hasClass("down") && $(this).toggleClass("up");
        $(this).hasClass("arrow") && $(this).toggleClass("arrowDown");
        $(this).hasClass("in") && $(this).toggleClass("out");

        // positionが被るとプルダウンメニューの挙動がおかしくなるため///////////////////////////////
        if ($(".TestAboutBox__link").hasClass("out")) {
          $(".fmenuPosition").css("position", "static");
        } else {
          $(".fmenuPosition").css("position", "relative");
        }
      },
    });
  };
  $(".TestList__ListTitle").on("click", function () {
    $(this).next().next().trigger("click");
  });

  // 試験一覧プルダウン////////////////////////////////////////////////////////////////////////////
  // sp以外//////////
  $(".testMenu").hover(
    function () {
      $(this).find("#testMenu__wrap:not(:animated)").slideDown();
    },
    function () {
      $(this).find("#testMenu__wrap").slideUp();
    }
  );
  $(".fmenuPosition").hover(
    function () {
      $(this).find(".fTestmenu:not(:animated)").slideDown();
    },
    function () {
      $(this).find(".fTestmenu").slideUp();
    }
  );

  //読み込み完了後の処理/////////////////////////////////////////////////////////////////////////
  /* ローディング*/
  wd.on("load", function () {
    effectInit();
    accodion();
    //画面表示
    loader.fadeOut(500);
  });

  // sp版ハンバーガーメニューテキスト入れ替え////////////////////////////////////////////////////////
  var menu = $(".spHeader__menu");
  var menuCl = $(".spHeader__close");
  var menuJp = $(".spHeader__jp a");
  var menuEn = $(".spHeader__en a");
  var menuEnbox = $(".spHeader__en--box");
  var menuForm = $(".spHeader__form a");
  var testList = $(".rollUp");
  menu.show();
  menuCl.hide();
  menuEnbox.show();
  $(".drawer").click(function () {
    if ($(".drawer").hasClass("drawer-open")) {
      menuJp.hide();
      menuEn.hide();
      menuForm.hide();
      menu.hide();
      menuCl.show();
      menuEnbox.show();
    } else if ($(".drawer").hasClass("drawer-close")) {
      menuJp.show();
      menuEn.show();
      menuForm.show();
      menu.show();
      menuCl.hide();
      menuEnbox.show();
    }
  });
  $(".drawer").on("drawer.opened", function () {
    menuJp.hide();
    menuEn.hide();
    menuForm.hide();
    menu.hide();
    menuCl.show();
    menuEnbox.show();
  });
  $(".drawer").on("drawer.closed", function () {
    menuJp.show();
    menuEn.show();
    menuForm.show();
    menu.show();
    menuCl.hide();
    menuEnbox.show();
    testList.hide();
  });

  $("#file").on("change", function () {
    if (!$("#file").val()) {
      $("#dummyTextBox").val($("#file").val());
    } else {
      $("#dummyTextBox").val($("#file").prop("files")[0].name);
    }
  });

  // フォームバリデーションエラー表示位置変更////////////////////////////////////////////////////////
  $(document).on("wpcf7:invalid", function (evt) {
    $("span.acceptance-102 .wpcf7-not-valid-tip").insertBefore(
      ".wpcf7-custom-item-error"
    );
  });

  // 地図切り替え
  var map1 = $(".googleMap1");
  var map2 = $(".googleMap2");
  var map3 = $(".googleMap3");

  map1.show();
  map2.hide();
  map3.hide();
  $("#googlemapButton1").click(function () {
    $(this).addClass("current-map");
    $("#googlemapButton2").removeClass("current-map2");
    $("#googlemapButton3").removeClass("current-map3");
    map2.stop().fadeOut("200");
    map3.stop().fadeOut("200");
    // map1.fadeIn('200');
    setTimeout(function () {
      $(map1).stop().fadeIn("200");
    }, 400);
  });
  $("#googlemapButton2").click(function () {
    $(this).addClass("current-map2");
    $("#googlemapButton1").removeClass("current-map");
    $("#googlemapButton3").removeClass("current-map3");
    map1.stop().fadeOut("200");
    map3.stop().fadeOut("200");
    // map2.fadeIn('200');
    setTimeout(function () {
      $(map2).stop().fadeIn("200");
    }, 400);
  });
  $("#googlemapButton3").click(function () {
    $(this).addClass("current-map3");
    $("#googlemapButton1").removeClass("current-map");
    $("#googlemapButton2").removeClass("current-map2");
    map1.stop().fadeOut("200");
    map2.stop().fadeOut("200");
    // map3.fadeIn('200');
    setTimeout(function () {
      $(map3).stop().fadeIn("200");
    }, 400);
  });

  // $('#googlemapButtonfirst').click(function(){
  //   $('#googlemapButton1').trigger('click');
  // });
  // $('#googlemapButtonsecond').click(function(){
  //   $('#googlemapButton2').trigger('click');
  // });
  // $('#googlemapButtonthird').click(function(){
  //   $('#googlemapButton3').trigger('click');
  // });
  $("#spgooglemapButton1").click(function () {
    $(this).addClass("current-map");
    $("#spgooglemapButton2").removeClass("current-map2");
    $("#spgooglemapButton3").removeClass("current-map3");
    map2.stop().fadeOut("200");
    map3.stop().fadeOut("200");
    // map1.fadeIn('200');
    setTimeout(function () {
      $(map1).stop().fadeIn("200");
    }, 400);
  });
  $("#spgooglemapButton2").click(function () {
    $(this).addClass("current-map2");
    $("#spgooglemapButton1").removeClass("current-map");
    $("#spgooglemapButton3").removeClass("current-map3");
    map1.stop().fadeOut("200");
    map3.stop().fadeOut("200");
    // map2.fadeIn('200');
    setTimeout(function () {
      $(map2).stop().fadeIn("200");
    }, 400);
  });
  $("#spgooglemapButton3").click(function () {
    $(this).addClass("current-map3");
    $("#spgooglemapButton1").removeClass("current-map");
    $("#spgooglemapButton2").removeClass("current-map2");
    map1.stop().fadeOut("200");
    map2.stop().fadeOut("200");
    // map3.fadeIn('200');
    setTimeout(function () {
      $(map3).stop().fadeIn("200");
    }, 400);
  });

  // $(".testMenu2").click(function () {
  //   if ($(this).hasClass("js-click-open")) {
  //     $("ul:not(:animated)", this).slideDown();
  //     $(".testMenu2").addClass("js-click-close");
  //     $(".testMenu2").removeClass("js-click-open");
  //   } else if ($(".testMenu2").hasClass("js-click-close")) {
  //     $("ul.rollUp2", this).slideUp();
  //     $(".testMenu2").removeClass("js-click-close");
  //     $(".testMenu2").addClass("js-click-open");
  //   }
  // });

  $(".testMenu2").hover(
    function () {
      $("ul:not(:animated)", this).slideDown();
    },
    function () {
      $("ul.rollUp2", this).slideUp();
    }
  );

  // $(".testMenu2").hover(
  //   function () {
  //     $("ul:not(:animated)", this).fadeIn();
  //   },
  //   function () {
  //     $("ul.rollUp2", this).fadeOut();
  //   }
  // );

  // $(".testMenu2").hover(
  //   function () {
  //     $("ul:not(:animated)", this).show(300);
  //   },
  //   function () {
  //     $("ul.rollUp2", this).hide(300);
  //   }
  // );

  $("#testMenu__wrap-sp").click(function () {
    $("#testMenu__wrap-sp_list").slideToggle();
  });

  $("#testMenu__spbtn2").click(function () {
    $("#testMenu__wrap2").slideToggle();
  });
};
