import "../../css/scss/pages/form.scss";
import "scroll-behavior-polyfill";

export const Form = () => {
  const handleCheckBox = () => {};
  typeof window !== `undefined` &&
    typeof document.querySelectorAll(
      `.wpcf7-acceptance input[type="checkbox"]`
    ) !== `undefined` &&
    window.addEventListener("load", handleCheckBox);
};
