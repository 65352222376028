export const scrollToTop = () => {

  const anchorButtons = document.querySelectorAll('.js-scroll-to-top');

  if(anchorButtons) {
    anchorButtons.forEach((button) => {
      toTop(button);
    })
  }

  const toTop = (button) => {
    button.addEventListener('click', (event) => {
      // a要素のデフォルトの挙動をキャンセル。
      // button[type="button"]の場合は不要です。
      event.preventDefault();
        window.scrollTo({
          top: 0,
          behavior: "smooth"
      });
    });
  }
}

export const scrollToId = () => {

  const anchorButtons = document.querySelectorAll('.js-scroll-to-id');

  if(anchorButtons) {
    anchorButtons.forEach((button) => {
      toIdElement (button);
    })
  }

  const toIdElement = (button) => {
    // アンカーリンクのhrefから、移動先の要素を取得。
    const targetId = anchor.getAttribute('href');
    const target = document.querySelector(targetId);

    button.addEventListener('click', (event) => {
      // a要素のデフォルトの挙動をキャンセル。
      event.preventDefault();
      target.scrollIntoView({
        behavior: "smooth",
      });
    });
  }
}

