const ScrollTop = () => {
  const links = document.querySelectorAll('a[href^="#1"]');

  for (let i = 0; i < links.length; i++) {
    links[i].addEventListener("click", (e) => {
      e.preventDefault();

      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    });
  }
};
export default ScrollTop;
